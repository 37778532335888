import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, getDownloadURL } from 'firebase/storage';
import { certificateStorage } from '../../config/certificateFirebase';

import { useLanguage } from '../../context/LanguageContext';
import './CertificateVerification.css';

const CertificateVerification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { certificateId } = useParams();
  const { t } = useLanguage();

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        if (!certificateId) {
          setError(t('certificate.validation.error.idMissing'));
          return;
        }

        const certificateRef = ref(
          certificateStorage, 
          `certificados/${certificateId}_certificate.png`
        );
        
        const url = await getDownloadURL(certificateRef);
        setCertificateUrl(url);
      } catch (error) {
        console.error('Error fetching certificate:', error);
        setError(t('certificate.validation.error.notFound'));
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
    setTimeout(() => setIsVisible(true), 100);
  }, [certificateId, t]);

  return (
    <div>
      <div className="certificate-verification-page">
        <div className={`verification-container ${isVisible ? 'visible' : ''}`}>
          <div className="verification-badge">
            <div className="verification-icon">
              <i className="fas fa-shield-check"></i>
            </div>
            <div className="badge-ring"></div>
            <div className="badge-ring-outer"></div>
          </div>
          
          <h1>
            <span className="highlight">{t('certificate.validation.title')}</span>
            <span className="checkmark">
              <i className="fas fa-check-circle"></i>
            </span>
          </h1>

          {loading ? (
            <div className="certificate-loading">
              <div className="loading-spinner"></div>
              <p>{t('certificate.validation.loading')}</p>
            </div>
          ) : error ? (
            <div className="certificate-error">
              <i className="fas fa-exclamation-circle"></i>
              <p>{error}</p>
            </div>
          ) : (
            <div className="certificate-image-container">
              <img 
                src={certificateUrl} 
                alt={t('certificate.validation.title')}
                className="certificate-image"
                onError={() => {
                  setError(t('certificate.validation.error.loadingImage'));
                }}
              />
            </div>
          )}
          
          <div className="verification-details">
            <p className="main-message">
              <i className="fas fa-certificate message-icon"></i>
              {t('certificate.validation.mainMessage')}
            </p>
            
            <div className="verification-info">
              <div className="info-card">
                <i className="fas fa-lock"></i>
                <div>
                  <h3>{t('certificate.validation.secureTitle')}</h3>
                  <p>{t('certificate.validation.secureDescription')}</p>
                </div>
              </div>
              
              <div className="info-card">
                <i className="fas fa-award"></i>
                <div>
                  <h3>{t('certificate.validation.recognitionTitle')}</h3>
                  <p>{t('certificate.validation.recognitionDescription')}</p>
                </div>
              </div>
            </div>

            <div className="verification-footer">
              <div className="verification-seal">
                <i className="fas fa-stamp"></i>
                <div className="seal-info">
                  <p className="timestamp">
                    {t('certificate.validation.verifiedAt', {
                      date: new Date().toLocaleDateString(t('common.locale'), {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    })}
                  </p>
                  <p className="platform-info">
                    {t('certificate.validation.platformInfo')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateVerification; 