import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { LanguageProvider } from '../../context/LanguageContext';
import AppDownloadBanner from '../AppDownloadBanner';
import { BannerProvider } from '../../context/BannerContext';

const Layout = ({ children }) => {
  return (
    <LanguageProvider>
      <BannerProvider>
        <div className="app">
          <Header />
          <main>{children} <AppDownloadBanner /></main>
          <Footer />
        </div>
      </BannerProvider>
    </LanguageProvider>
  );
};

export default Layout; 