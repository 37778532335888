import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import './AppDownloadBanner.css';
import appStoreBadge from '../../assets/images/app-store-badge.png';
import playStoreBadge from '../../assets/images/play-store-badge.png';

const AppDownloadBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    const hasSeenBanner = localStorage.getItem('hasSeenAppBanner');
    
    if (!hasSeenBanner) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        setIsAnimating(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setIsAnimating(false);
    localStorage.setItem('hasSeenAppBanner', 'true');
    
    setTimeout(() => {
      setIsVisible(false);
    }, 300);
  };

  if (!isVisible) return null;

  return (
    <div className={`app-download-banner ${isVisible ? 'show' : ''}`}>
      <div className={`banner-content ${isAnimating ? 'animate-in' : 'animate-out'}`}>
        <div className="banner-text-wrapper">
          <div className="banner-icon">
            <i className="fas fa-mobile-alt"></i>
          </div>
          <div className="banner-text">
            <h3>{t('appBanner.title')}</h3>
            <p>{t('appBanner.description')}</p>
          </div>
        </div>
        <div className="banner-actions">
          <a 
            href="https://apps.apple.com/app/beharv"
            target="_blank"
            rel="noopener noreferrer"
            className="app-store-button"
            aria-label={t('appBanner.appStore')}
          >
            <img 
              src={appStoreBadge} 
              alt={t('appBanner.appStore')} 
              className="store-badge"
            />
          </a>
          <a 
            href="https://play.google.com/store/apps/details?id=tech.jobholding.beharv"
            target="_blank"
            rel="noopener noreferrer"
            className="play-store-button"
            aria-label={t('appBanner.playStore')}
          >
            <img 
              src={playStoreBadge} 
              alt={t('appBanner.playStore')} 
              className="store-badge"
            />
          </a>
        </div>
        <button 
          className="close-banner" 
          onClick={handleClose}
          aria-label={t('appBanner.close')}
        >
          <span className="close-icon">&times;</span>
        </button>
      </div>
    </div>
  );
};

export default AppDownloadBanner; 