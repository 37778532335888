import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const certificateFirebaseConfig = {
  apiKey: process.env.REACT_APP_CERTIFICATE_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_CERTIFICATE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_CERTIFICATE_FIREBASE_PROJECT_ID,
  storageBucket: "proj-solicita-image.appspot.com",
  messagingSenderId:
    process.env.REACT_APP_CERTIFICATE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_CERTIFICATE_FIREBASE_APP_ID,
};

const certificateApp = initializeApp(certificateFirebaseConfig, "certificate");
const certificateStorage = getStorage(certificateApp);

export { certificateStorage };
