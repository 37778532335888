import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInstitutionalOpen, setIsInstitutionalOpen] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    window.location.href = 'https://courses.beharv.com';
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <button
          className={`hamburger-menu ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </button>

        <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`}>
          <div className="mobile-nav-items">
            <ul>
              <li>
                <Link to="/" onClick={closeMenu}>
                  {t('navigation.menu.home')}
                </Link>
              </li>
              <li>
                <Link to="/courses" onClick={closeMenu}>
                  {t('navigation.menu.courses')}
                </Link>
              </li>
              <li className="dropdown-item">
                <button 
                  className="dropdown-toggle"
                  onClick={() => setIsInstitutionalOpen(!isInstitutionalOpen)}
                >
                  {t('navigation.menu.institutional')}
                  <i className={`fas fa-chevron-${isInstitutionalOpen ? 'up' : 'down'}`}></i>
                </button>
                <div className={`dropdown-menu ${isInstitutionalOpen ? 'show' : ''}`}>
                  <Link to="/about" onClick={closeMenu}>
                    {t('footer.sections.institutional.about')}
                  </Link>
                  <Link to="/terms" onClick={closeMenu}>
                    {t('footer.sections.institutional.terms')}
                  </Link>
                  <Link to="/privacy" onClick={closeMenu}>
                    {t('footer.sections.institutional.privacy')}
                  </Link>
                  <Link to="/contact" onClick={closeMenu}>
                    {t('footer.sections.institutional.contact')}
                  </Link>
                </div>
              </li>
              <li>
                <a href="https://courses.beharv.com" className="cta-button" onClick={closeMenu}>
                  {t('navigation.menu.login')}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul className="nav-menu">
          <li><Link to="/">{t('navigation.menu.home')}</Link></li>
          <li><Link to="/courses">{t('navigation.menu.courses')}</Link></li>
          <li className="nav-dropdown">
            <button 
              className="dropdown-toggle"
              onMouseEnter={() => setIsInstitutionalOpen(true)}
              onMouseLeave={() => setIsInstitutionalOpen(false)}
            >
              {t('navigation.menu.institutional')}
              <i className="fas fa-chevron-down"></i>
            </button>
            <div className={`dropdown-menu ${isInstitutionalOpen ? 'show' : ''}`}
              onMouseEnter={() => setIsInstitutionalOpen(true)}
              onMouseLeave={() => setIsInstitutionalOpen(false)}
            >
              <Link to="/about">{t('footer.sections.institutional.about')}</Link>
              <Link to="/terms">{t('footer.sections.institutional.terms')}</Link>
              <Link to="/privacy">{t('footer.sections.institutional.privacy')}</Link>
              <Link to="/contact">{t('footer.sections.institutional.contact')}</Link>
            </div>
          </li>
        </ul>

        <div className="nav-buttons">
          <a href="https://courses.beharv.com" className="cta-button">
            {t('navigation.menu.login')}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 