import React from 'react';
import CourseSection from './CourseSection';

const CoursesMainPage = () => {
  return (
    <div className="home-page">
      <CourseSection />
    </div>
  );
};

export default CoursesMainPage; 