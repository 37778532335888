import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { useLanguage } from "../context/LanguageContext";

export const useModules = (courseId) => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    const fetchModules = async () => {
      if (!courseId) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const langPath = language === "es" ? "es_es" : "en_us";

        // Construct the path to modules collection
        const modulesRef = collection(
          db,
          `cursosMd/${courseId}/${langPath}/modulos/modulos`
        );

        const modulesSnapshot = await getDocs(modulesRef);

        const modulesList = modulesSnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().titulo,
        }));

        // Sort modules by their IDs (assuming they're numbered)
        modulesList.sort((a, b) => {
          const numA = parseInt(a.id);
          const numB = parseInt(b.id);
          return numA - numB;
        });

        setModules(modulesList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching modules:", err);
        setError(err);
        setLoading(false);
      }
    };

    fetchModules();
  }, [courseId, language]);

  return { modules, loading, error };
};
